import PropTypes from 'prop-types'
import * as workTypes from 'data/workTypes'
import * as React from 'react'
import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { ContainerStyled, SectionStyled } from 'components/UI'

import CaseItem from './card'
import * as S from './styled'

function findSuitableWorks(allWorks, searchConfig, limit) {
  const ratesItems = allWorks
    .map((item, index) => {
      let itemRate = 0

      if (item.id !== searchConfig.excludeId) {
        if (searchConfig.tags) {
          // +1 for each tag
          itemRate += item.workTags.nodes.reduce((prevVal, tag) => {
            return searchConfig.tags.includes(tag.name) ? prevVal + 1 : prevVal
          }, 0)
        }

        if (searchConfig.dateTime) {
          // +0.1 ... 2 - for dates
          const itemTime = new Date(item.workPage.date).getTime()
          itemRate += itemTime / searchConfig.dateTime
        }

        if (searchConfig.projectType) {
          // +1 for project type
          itemRate +=
            searchConfig.projectType === item.workPage.projectType ? 1 : 0
        }
      }
      return {
        index,
        rate: itemRate,
      }
    })
    .sort((prev, next) => {
      return next.rate - prev.rate
    })
    .slice(0, limit)

  return ratesItems.map((item) => allWorks[item.index])
}

function DiscoverMore({ compareProject, config }) {
  const {
    allWpWork: { nodes: allWorks },
  } = useStaticQuery(graphql`
    query DiscoveryWorksQuery {
      allWpWork {
        ...worksListQuery
      }
    }
  `)

  const casesList = useMemo(() => {
    const compareProjectTags = compareProject.tags.map((tag) => tag.name)
    const compareProjectDateTime = compareProject.date
      ? new Date(compareProject.date).getTime()
      : new Date().getTime()

    let filteredItems = []
    switch (config.moreFilterType) {
      case 'custom':
        filteredItems = config.worksList || []
        break
      case 'by_tag':
        filteredItems = findSuitableWorks(
          allWorks,
          {
            excludeId: compareProject.workId,
            tags: config.moreTags || compareProjectTags,
            projectType: null,
            dateTime: compareProjectDateTime,
          },
          config.countMoreWork
        )
        break
      case 'by_date':
        filteredItems = findSuitableWorks(
          allWorks,
          {
            excludeId: compareProject.workId,
            tags: null,
            projectType: null,
            dateTime: config.moreDate.date
              ? new Date(config.moreDate.date).getTime()
              : compareProjectDateTime,
          },
          config.countMoreWork
        )
        break
      default:
        filteredItems = findSuitableWorks(
          allWorks,
          {
            excludeId: compareProject.workId,
            tags: compareProjectTags,
            projectType: compareProject.type,
            dateTime: compareProjectDateTime,
          },
          config.countMoreWork
        )
    }

    return filteredItems
  }, [allWorks])

  return (
    <SectionStyled
      $paddingBottom={{ xs: 80, md: 90, lg: 100 }}
      $paddingTop={{ xs: 60, sm: 90, lg: 120 }}
    >
      <ContainerStyled>
        <S.title>{config.moreProjectLabel}</S.title>
        <S.casesList>
          {casesList.map((item, index) => {
            return <CaseItem card={item} key={index} />
          })}
        </S.casesList>
      </ContainerStyled>
    </SectionStyled>
  )
}

DiscoverMore.propTypes = {
  config: workTypes.workSettingsType,
  compareProject: PropTypes.shape({
    tags: PropTypes.arrayOf(workTypes.workTagType),
    workId: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string,
  }),
}

DiscoverMore.defaultProps = {
  compareProject: null,
  config: {
    moreProjectLabel: 'Discover More',
    countMoreWork: 3,
    worksList: [],
    moreTags: [],
  },
}
export default DiscoverMore
