import * as workTypes from 'data/workTypes'
import * as React from 'react'
import ResponsiveImage from 'components/ResponsiveImage'
import { useContext } from 'react'
import { CursorContext } from 'context'
import { HiddenTextStyled, HashtagStyled } from 'components/UI'
import FadeUpAnimation from 'components/Animations/FadeUpAnimation'
import * as S from './styled'

function CaseItem({ card }) {
  const { onCursor } = useContext(CursorContext)
  const FullDate = new Date(card.workPage.date)
  const FullYear = FullDate.getFullYear()
  return (
    <FadeUpAnimation>
      {(refItem) => (
        <S.Card
          ref={refItem}
          onMouseEnter={() => onCursor('discover')}
          onMouseLeave={onCursor}
        >
          <S.CardLink to={card.uri}>
            <HiddenTextStyled>{card.workPage.title}</HiddenTextStyled>
          </S.CardLink>
          <S.Media>
            <S.Picture>
              <ResponsiveImage gatsbyImageData={card.workPage.smallPreview} />
            </S.Picture>
          </S.Media>
          <S.CardContent>
            <S.Headline>
              <S.Title>{card.workPage.title}</S.Title>
              <S.Date>{FullYear}</S.Date>
            </S.Headline>
            <S.HashtagList>
              {card.workTags.nodes.map((item, index) => {
                return (
                  <HashtagStyled as="li" $size="small" key={index}>
                    {item.name}
                  </HashtagStyled>
                )
              })}
            </S.HashtagList>
          </S.CardContent>
        </S.Card>
      )}
    </FadeUpAnimation>
  )
}

CaseItem.propTypes = {
  card: workTypes.workCardType.isRequired,
}

CaseItem.defaultProps = {}
export default CaseItem
