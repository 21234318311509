import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ResponsiveImage from 'components/ResponsiveImage'
import * as customTypes from 'data/types'
import BackButton from 'components/BackButton'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ContainerStyled, SectionStyled } from 'components/UI'
import FadeUpAnimation from 'components/Animations/FadeUpAnimation'
import * as S from './styled'
import IconLink from './Icons/IconLink'

gsap.registerPlugin(ScrollTrigger)

function HeaderBlock({ date, summary, title, image, link }) {
  const projectDate = new Date(date)
  const imgRef = useRef(null)
  useEffect(() => {
    const el = imgRef.current
    gsap.fromTo(
      el,
      { scale: 0.725 },
      {
        scale: 1,
        duration: 3,
        scrollTrigger: {
          trigger: el,
          start: 'top 20%',
          end: 'bottom',
          scrub: true,
        },
      }
    )
  }, [])

  return (
    <SectionStyled
      $paddingBottom={{ xs: 60, md: 90, lg: 100 }}
      $paddingTop={{ xs: 100, lg: 180 }}
    >
      <ContainerStyled>
        <BackButton />
        <FadeUpAnimation>
          {(refItem) => (
            <S.container ref={refItem}>
              <S.title>{title}</S.title>
              <S.wrapper>
                <S.Inner>
                  <S.summary>{summary}</S.summary>
                  {link && (
                    <S.LinkToProject to={link}>
                      <IconLink />
                    </S.LinkToProject>
                  )}
                </S.Inner>
                <S.date>{projectDate.getFullYear()}</S.date>
              </S.wrapper>
            </S.container>
          )}
        </FadeUpAnimation>
      </ContainerStyled>
      <S.Media>
        <ResponsiveImage gatsbyImageData={image} />
      </S.Media>
      <S.MediaAnimate ref={imgRef}>
        <ResponsiveImage gatsbyImageData={image} />
      </S.MediaAnimate>
    </SectionStyled>
  )
}

HeaderBlock.propTypes = {
  date: PropTypes.string,
  summary: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  image: customTypes.gatsbyImageType.isRequired,
}

HeaderBlock.defaultProps = {
  date: '',
  summary: '',
  title: '',
  link: '',
}
export default HeaderBlock
